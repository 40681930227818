/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

import Form from './components/Form'
import Slider from './components/Slider'
import Menu from './components/Menu'
import ScrollTo from './components/ScrollTo'

import objectFitImages from 'object-fit-images';
import AOS from 'aos';

document.addEventListener('DOMContentLoaded', (event) => {
    Form.validation();
    Slider.init();
    Menu.setHeight();
    Menu.setScrolling();
    Menu.listenOpen();
    Menu.handleSubmenu();
    Menu.handleLinkClick();
    ScrollTo.init();

    AOS.init({
        once: true,
        duration: 500,
    });
    objectFitImages();
});
