import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

/**
 * Handle menu
 */
export default class Menu {

    /**
     * Set menu's height to body's padding top to make space for menu.
     */
    static setHeight() {
        const body = document.body;
        const menu = document.getElementById('menu-container');

        if (menu) {
            setTimeout(() => {
                body.style.paddingTop = `${menu.offsetHeight}px`;
            }, 200);
        }
    }
    
    /**
     * Hide and show menu on scrolling.
     */
    static setScrolling() {
        let prevScrollPos = window.pageYOffset;
        
        function init() {
            let menu = document.getElementById('menu-container');
            let currentScrollPos = window.pageYOffset;
            
            // if menu's height is bigger than scrolled area start scrolling
            if (menu && menu.offsetHeight < currentScrollPos) {
                menu.classList.add('menu-container--is-scrolled');
                
                //if (currentScrollPos > prevScrollPos) {
                   // menu.classList.remove('menu-container--is-scrolled-top');
                //} else {
                    //menu.classList.add('menu-container--is-scrolled-top');
                //}
            } else if (menu) {
                menu.classList.remove('menu-container--is-scrolled');
                //menu.classList.remove('menu-container--is-scrolled-top');
            }
            
            prevScrollPos = currentScrollPos;
        }
        
        // init menu on load
        init();
        window.onscroll = function () {
            // call menu on scroll
            init();
        };
    }

    /**
     * Set overflow hidden to body if menu is open (ban background-scrolling).
     */
    static listenOpen() {
        const checkbox = document.getElementById('nav-toggle'),
            navList = document.getElementById('nav-list');

        if (checkbox) {
            checkbox.addEventListener('change', function(){
                if (checkbox.checked) {
                    disableBodyScroll(navList);
                } else {
                    enableBodyScroll(navList);
                }
            });
        }
    }

    static handleLinkClick() {
        const checkbox = document.getElementById('nav-toggle');
        const linkList = document.querySelectorAll('#menu-container .js-scroll-to');
        const navList = document.getElementById('nav-list');

        if (linkList.length) {
            linkList.forEach(link => {
                link.addEventListener('click', e => {
                    checkbox.checked = false;
                    enableBodyScroll(navList);
                });
            });
        };
    }

    static handleSubmenu() {
        const triggerList = document.querySelectorAll('.submenu-trigger');

        if (triggerList) {
            triggerList.forEach(item => {
                item.addEventListener('click', () => {
                    item.classList.toggle('nav__item--submenu-opened');
                });

                document.addEventListener('click', e => {
                    if (!item.contains(e.target) && item !== e.target) {
                        item.classList.remove('nav__item--submenu-opened');
                    }
                })
            })
        }
    }
}
