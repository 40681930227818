import Swiper from 'swiper';

/**
 * Handle slider based on tiny-slider
 */
export default class Slider {

    static init() {
        Slider.initCarSlider();
    }

    static initCarSlider() {
        const carSlider = document.getElementById('car-slider');

        if (carSlider) {
            const slider = new Swiper(carSlider, {
                slidesPerView: 'auto',
                centeredSlides: true,
                navigation: {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                },
                loop: true,
                autoplay: {
                    delay: 5000,
                },
            });
        }
    }
}