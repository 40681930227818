/**
 * Handle forms
 */
export default class Form {

    static init() {
        Form.validation();
    }

    /**
     * Set custom validation on form with data-validation attribute
     * If attribute has value onInit, the form is validate on page load
     */
    static validation() {
        const formsToValidate = document.querySelectorAll('[data-validation]');

        if (formsToValidate.length > 0) {
            formsToValidate.forEach(form => {
                // validate on page load
                if (form.dataset.validation === 'onInit') {
                    Form.validateForm(form);
                }

                // set listeners on required inputs
                let requiredControls = form.querySelectorAll('[data-required]');
                requiredControls.forEach( control => {
                   Form.listenControl(control);
                });

                // add listener on submit
                form.addEventListener('submit', function (e) {
                    e.preventDefault();
                    Form.validateForm(form);
                    // based on errors submit form
                    const errors = document.querySelectorAll('.form-container--error');
                    if (errors.length === 0) {
                        form.submit();
                    }
                });
            });
        }
    }

    /**
     * Validate form (check required controls)
     * @param form
     */
    static validateForm(form) {
        let requiredControls = form.querySelectorAll('[data-required]');
        if (requiredControls.length > 0 ) {
            requiredControls.forEach( control => {
                Form.validateControl(control);
            });
        }
    }

    /**
     * E-mail validation
     * @param email
     * @returns {boolean}
     */
    static validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    /**
     * Main validation logic
     * @param control
     */
    static validateControl(control) {
        let parent = control.closest('.form-container');

        // determinate control type
        let isEmail = control.dataset.required === 'email';
        let isCheckbox = control.type === 'checkbox';

        // checkbox
        if (isCheckbox) {
            if (control.checked) {
                parent.classList.remove('form-container--error');
                parent.classList.add('form-container--success');
            } else {
                parent.classList.add('form-container--error');
                parent.classList.remove('form-container--success');
            }
        // text, textarea
        } else {
            if (control.value === '') {
                parent.classList.add('form-container--error');
                parent.classList.remove('form-container--success');
            } else {
                // specific type
                if (isEmail) {
                    if (this.validateEmail(control.value)) {
                        parent.classList.remove('form-container--error');
                        parent.classList.add('form-container--success');
                    } else {
                        parent.classList.add('form-container--error');
                        parent.classList.remove('form-container--success');
                    }
                } else {
                    parent.classList.remove('form-container--error');
                    parent.classList.add('form-container--success');
                }
            }
        }
    }

    /**
     * Set listeners on required controls
     * @param control
     */
    static listenControl(control) {
        control.addEventListener('input', (e) => {
            Form.validateControl(control);
        });
    }

    /**
     * Toggle visibility of area based on checkbox value.
     * Selector of area is set in data-checked attribute.
     */
    static handleChecked() {
        const triggers = document.querySelectorAll('input[data-checked]');

        if (triggers.length > 0) {
            triggers.forEach( trigger => {
                trigger.addEventListener('input', () => {
                    let el = trigger.dataset.checked;
                    let elSelector = document.querySelector(el);
                    if (elSelector) {
                        elSelector.classList.toggle('none');
                    }
                });
            });
        }
    }
}
